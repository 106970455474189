import React from 'react';
import Layout from '../components/Layout';
import BreadcrumbSchema from '../components/BreadcrumbSchema';
import Breadcrumbs from '../components/Breadcrumbs';
import { Helmet } from 'react-helmet';

const PrivacyPolicyPage = () => {
  const title =
    'プライバシーポリシー｜奈良のホームページ制作なら｜インヴォルブ';
  const description =
    '当サイトのプライバシーポリシーです。個人情報の取り扱いおよびクッキーの使用について説明しています。';
  const breadcrumbList = [
    { name: 'ホーム', url: 'https://www.involve-in.jp/' },
    {
      name: 'プライバシーポリシー',
      url: 'https://www.involve-in.jp/privacy-policy',
    },
  ];

  return (
    <Layout>
      <BreadcrumbSchema breadcrumbList={breadcrumbList} />
      <Helmet>
        <title>{title}</title>
        <meta name="description" content={description} />
        <meta name="robots" content="index, follow" />
        <link
          rel="canonical"
          href="https://www.involve-in.jp/privacy-policy/"
        />
        <link
          rel="icon"
          type="image/x-icon"
          href="/images/common/favicon.ico"
        />

        {/* Open Graph (OGP: Facebook, LinkedIn) */}
        <meta property="og:title" content={title} />
        <meta property="og:description" content={description} />
        <meta property="og:type" content="website" />
        <meta
          property="og:url"
          content="https://www.involve-in.jp/privacy-policy"
        />
        <meta
          property="og:image"
          content="https://www.involve-in.jp/images/common/ogp.png"
        />

        {/* Twitter Card */}
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:title" content={title} />
        <meta name="twitter:description" content={description} />
        <meta
          name="twitter:image"
          content="https://www.involve-in.jp/images/common/ogp.png"
        />
      </Helmet>
      <div className="max-w-screen-lg w-full px-16 mx-auto py-32 bg-white bg-opacity-90">
        <h1 className="font-roboto text-6xl md:text-[10rem] font-bold text-center mb-12">
          Privacy Policy
        </h1>
        <section className="container mx-auto py-24 px-6">
          <h2 className="text-2xl font-semibold mb-4">1. はじめに</h2>
          <p className="text-2xl leading-relaxed mb-12">
            当サイト（以下、「当ウェブサイト」）では、利用者の個人情報を適切に取り扱い、プライバシーの保護に努めています。本プライバシーポリシーでは、個人情報の収集、利用、およびクッキーの使用について説明します。
          </p>

          <h2 className="text-2xl font-semibold mb-4">
            2. 個人情報の収集・利用
          </h2>
          <p className="text-2xl leading-relaxed mb-12">
            当ウェブサイトでは、通常の閲覧において個人情報の提供は不要です。ただし、お問い合わせなどの際に、氏名・メールアドレスなどの情報を収集する場合があります。取得した情報は、お問い合わせ対応およびサービス向上のために利用し、ご本人の同意なしに第三者へ提供することはありません。
          </p>

          <h2 className="text-2xl font-semibold mb-4">
            3. クッキー（Cookies）の使用
          </h2>
          <p className="text-2xl leading-relaxed mb-12">
            当ウェブサイトでは、利便性向上およびアクセス解析のためにクッキーを使用しています。クッキーは、お使いのブラウザに保存される小さなデータファイルで、サイトの利用状況を分析し、サービス改善に役立てるために利用されます。
          </p>

          <h3 className="text-xl font-semibold mb-4">3.1 クッキーの種類</h3>
          <p className="text-2xl leading-relaxed mb-12">
            必須クッキー:サイトの基本機能を提供するために必要不可欠なクッキー。
          </p>
          <p className="text-2xl leading-relaxed mb-12">
            分析クッキー:Google Analytics によるアクセス解析のためのクッキー。
          </p>

          <h3 className="text-xl font-semibold mb-4">3.2 クッキーの管理</h3>
          <p className="text-2xl leading-relaxed mb-12">
            クッキーの利用は、ユーザーのブラウザ設定で管理・拒否できます。
            設定方法の詳細は、以下の公式ガイドをご参照ください：
            <a
              href="https://www.google.com/analytics/terms/jp.html"
              target="_blank"
              rel="noopener noreferrer"
              className="text-blue-500 hover:text-blue-700"
            >
              Google Analytics の詳細はこちら
            </a>
          </p>

          <h2 className="text-2xl font-semibold mb-4">
            4. アクセス解析ツールの使用
          </h2>
          <p className="text-2xl leading-relaxed mb-12">
            当サイトでは、Google Analytics を利用しています。Google Analytics
            は、訪問者のサイト利用状況を分析するためにクッキーを使用します。収集される情報は匿名であり、個人を特定するものではありません。
          </p>

          <h2 className="text-2xl font-semibold mb-4">5. 免責事項</h2>
          <p className="text-2xl leading-relaxed mb-12">
            当ウェブサイトからリンクされた外部サイトの内容や個人情報の取り扱いについては、一切の責任を負いません。
          </p>

          <h2 className="text-2xl font-semibold mb-4">
            6. プライバシーポリシーの変更
          </h2>
          <p className="text-2xl leading-relaxed mb-12">
            本ポリシーは随時改訂される可能性があります。変更がある場合は、本ページにてお知らせします。
          </p>
          <h2 className="text-2xl font-semibold mb-4">7. お問い合わせ</h2>
          <p className="text-2xl leading-relaxed mb-12">
            クッキーポリシーおよび個人情報の取り扱いに関するご質問やお問い合わせは、以下の連絡先までお願いいたします。
          </p>
          <ul className="text-2xl leading-relaxed mb-12">
            <li>運営者名: 吉村浩嗣</li>
            <li>
              お問い合わせフォーム:{' '}
              <a
                href="/contact"
                target="_blank"
                rel="noopener noreferrer"
                className="text-blue-500 hover:text-blue-700"
              >
                こちら
              </a>
            </li>
            <li>メール: yoshimura@involve-in.jp</li>
          </ul>
        </section>
      </div>
      <Breadcrumbs breadcrumbList={breadcrumbList} />
    </Layout>
  );
};

export default PrivacyPolicyPage;
